<template>

<div class="menu">

	<com-context />

	<com-item route="Categories" icon="categories" text="Categories" />
	<com-item route="Groups" icon="groups" text="Groups" />
	<com-item route="Items" icon="items" text="Items" />
	<com-item route="Locations" icon="locations" text="Locations" />
	<com-item route="Texts" icon="texts" text="Content" />
	<com-item route="Terms" icon="terms" text="Terms" />

	<com-item route="Requests" icon="request" text="Requests" />
	<com-item route="Embed" icon="embed" text="Embed" />
	<com-item route="Share" icon="share" text="Share" />

	<com-item route="Accesses" icon="access" text="Access" />
	<com-item route="Settings" icon="settings" text="Settings" />

	<com-divide />

	<com-item route="Logout" icon="logout" text="Logout" />

</div>

</template>

<script>

export default {

	components: {
		'com-context': () => import('./menu/Context'),
		'com-item': () => import('./menu/Item'),
		'com-divide': () => import('./menu/Divide')
	}

}

</script>

<style scoped>

.menu {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 64px;
	bottom: 0px;
	background-color: #0b234e;
	display: flex;
	flex-direction: column;
	z-index: 3;
	overflow: hidden;
}

</style>
